<template>
  <b-col id="team-container" cols="12">
    <b-row
      v-if="$store.getters['teams/teamActive']"
      id="button-row"
      no-gutters
      align-h="center"
    >
    </b-row>

    <b-row id="team-content-row">
      <b-col cols="12" class="custom-table-head"> </b-col>
      <b-col
        :cols="$store.getters['teams/isTeamMember'] ? 6 : 12"
        class="table-col"
      >
        <TeamTable
          v-if="$store.getters['teams/isTeamMember']"
          :activity-config="activityConfig" />
        <JoinTeam
          v-else-if="!$store.getters['teams/isTeamMember']"
          @join="joinTeam"
      /></b-col>
      <b-col
        v-if="$store.getters['teams/isTeamMember']"
        cols="6"
        class="table-col"
      >
        <TeamWishCard @refreshCardWish="$emit('refreshCardWish')" />
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import TeamTable from "./team-table.vue";
import JoinTeam from "./team-join.vue";
import TeamWishCard from "@/components/team/team-wish-card";

export default {
  name: "TeamContainer",
  components: {
    TeamWishCard,
    TeamTable,
    JoinTeam,
  },

  computed: {
    activityConfig() {
      return this.$store.state.generalConfig.user.activity;
    },
  },
  mounted() {
    this.$store.commit("setIsPageLoading", false);
  },
  methods: {
    joinTeam(teamId) {
      this.$store
        .dispatch("teams/joinTeam", teamId)
        .then((res) => {
          this.$store.commit("popups/setAlertBannerContent", {
            alertText: this.$t("teams.join_team.join_success", {
              0: res.data.data.team.name,
            }),
            alertHeading: this.$t("alerts.title.success"),
            type: "check",
          });
        })
        .catch((e) => {
          console.log(e);
          this.displayErrorMessage();
        });
    },
  },
};
</script>

<style lang="scss">
#team-container {
  padding: 0;
  position: relative;
  z-index: 1;
  #button-row {
    .button-col {
      pointer-events: auto;
    }
    #card-wish-countdown {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      font-size: 16px;
      img {
        width: 32px;
        height: 32px;
        position: absolute;
        left: 0;
        bottom: 23px;
        z-index: 1;
      }
      .countdown-digital {
        color: $light-blue;
        background-image: linear-gradient(#0e6473, #050d1c 41%, #050d1c);
        box-shadow: 0 0 6px $light-blue;
        width: 60%;
        text-align: center;
      }
      .lock {
        height: 24px;
        width: 20px;
        background: url(~@/assets/img/common/lock-gold.png) center/100% 100%
          no-repeat;
        position: absolute;
        right: -10px;
        bottom: 4px;
        filter: grayscale(0%);
        transform: rotate(10deg);
      }
    }
  }
}
</style>
