<template>
  <b-table
    v-if="items.length > 0"
    id="chat-members"
    :items="items"
    :fields="fields"
    @row-clicked="openDetailsModal"
  >
    <!-- Custom column slot that display's a friend's avatar along with their name -->
    <!-- value reference the table cell's value, item the whole row -->
    <template v-slot:cell(name)="{ value }">
      <span class="user-name medium text-normal text-left">{{
        shortenString(value, 18)
      }}</span>
    </template>
    <template v-slot:cell(online)="{ item }">
      <div class="w-100 d-flex justify-content-center">
        <div
          class="online-indicator"
          :class="{
            online: isOnline(item.id),
            deleted: item.deleted_at,
          }"
        ></div></div
    ></template>
  </b-table>
</template>

<script>
import getPublicUserInfos from "@/mixins/getPublicUserInfos.js";

export default {
  name: "ChatMembers",
  components: {},
  mixins: [getPublicUserInfos],
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        { key: "online", label: "Status", sortable: false },
      ],
    };
  },
  computed: {
    items() {
      if (!this.$store.getters["auth/isLoggedIn"]) return [];
      return this.$store.state.teams.userTeam.users;
    },
  },
  mounted() {},
  methods: {
    isOnline(id) {
      if (!this.$store.getters["auth/isLoggedIn"]) {
        return false;
      } else if (this.$store.state.chat.onlineTeamMembers.length === 0) {
        return false;
      } else {
        let result = this.$store.state.chat.onlineTeamMembers.findIndex(
          (member) => member.id === id
        );
        if (result === -1) {
          return false;
        } else {
          return true;
        }
      }
    },
    openDetailsModal(row) {
      if (row.deleted_at) return;
      this.fetchPublicUser(row.id);
    },
  },
};
</script>

<style lang="scss">
#chat-members {
  thead th {
    border: none;
    text-transform: uppercase;
    color: #fff8b7;
    font-family: Ubuntu-bold;
    font-size: 16px;
    &:first-child {
      text-align: left;
    }
  }
  td {
    border-right: none !important;
    text-align: left;
  }
  tr:hover {
    cursor: pointer;
  }

  .online-indicator {
    width: 16px;
    height: 16px;
    background-color: #ff0000;
    border-radius: 50%;

    &.online {
      background-color: #43ff00;
    }
    &.deleted {
      background-image: url(~@/assets/img/friends/user-deleted.svg);
      background-size: 100% 100%;
    }
  }
}
</style>
