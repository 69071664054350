<template>
  <b-modal id="gift-alert" modal-class="alert-window" :hide-footer="true"
    ><template slot="modal-header" slot-scope="{ close }">
      <h4 class="modal-headline text-uppercase bold">
        {{ $t("teams.chat.gift_popup.headline") }}
      </h4>
      <h5 class="modal-subheadline text-white">
        {{
          $t("teams.chat.gift_popup.subheadline", {
            0: formatNumber(goldReward),
          })
        }}
      </h5>
      <a href="#" class="close-button" @click="close()"> </a>
    </template>
    <template slot="default">
      <b-row no-gutters class="gifting-section"
        ><b-col
          ><div
            v-if="card.image_urls"
            class="card-imgs"
            :style="{
              backgroundImage:
                'url(' + card.image_urls.backgrounds.collected_url + ')',
            }"
          >
            <img
              draggable="false"
              :src="card.image_urls.image_url"
              class="card-img dead-center"
            /></div></b-col
        ><b-col
          class="d-flex flex-column align-items-center justify-content-center"
          ><img
            draggable="false"
            src="@/assets/img/common/three-coins.png"
            style="height: 84px"
          />
          <span v-if="goldReward" class="text-white bold gold-amount">
            + {{ formatNumber(goldReward) }}
          </span></b-col
        ></b-row
      >
      <b-row
        no-gutters
        align-h="center"
        align-v="center"
        class="gift-button-section"
        ><button class="ok-button-green round-new" @click="$emit('cardGifted')">
          {{ $t("teams.chat.gift_popup.gift_button") }}
        </button></b-row
      >
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "GiftAlertModal",
  props: {
    card: {
      type: Object,
      required: true,
    },
    goldReward: {
      type: Number,
      required: true,
    },
  },
  computed: {},
  methods: {},
};
</script>
<style lang="scss">
#gift-alert {
  .modal-dialog {
    max-width: 600px;
  }
  .modal-header {
    flex-direction: column;
    align-items: center;
  }

  .modal-headline {
    text-transform: uppercase;
    font-size: 32px;
    color: #fff8b7;
  }
  .modal-subheadline {
    font-size: 18px;
  }

  .gifting-section {
    height: 224px;
    margin: 0 78px 50px 72px;
    .col {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
    }
    .card-imgs {
      height: 100%;
      width: 143px;
      background-size: 100% 100%;
      .card-img {
        max-width: 148px;
        max-height: 100%;
      }
    }
    .gold-amount {
      font-size: 26px;
      text-shadow: 0 0 13px rgba(77, 229, 255, 0.6),
        0 0 6px rgba(77, 229, 255, 0.6), 0 0 3px rgba(0, 94, 110, 0.8);
    }
  }

  .gift-button-section {
    .ok-button-green {
      font-size: 24px;
      width: 320px;
      height: 48px;
      border-radius: 24px;
      margin-bottom: -24px;
    }
  }
}
</style>
