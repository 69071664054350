<template>
  <div v-if="items.length > 0" id="friends-list" class="mt-5">
    <div class="w-75 position-relative">
      <h3 class="uppercase-subheadline position-relative">
        {{ $t("friends.friend_list.title") }}
        <InfoBadge
          id="info-friends-friendslist"
          :info-text="$t('info_badge.friends.friends_list')"
          :headline-text="$t('info_badge.friends.friends_list.headline')"
          placement="center"
          faq-link="friends"
          faq-article="360009873097"
        />
      </h3>
      <div id="friends-pagination">
        <table-pagination
          :per-page="perPage"
          :total-rows="totalRows"
          @input="updateCurrentPage"
        ></table-pagination>
      </div>
    </div>
    <div id="friends-grid" :class="fullWidth ? 'full' : ''">
      <div
        v-for="friend in paginatedFriends"
        :key="friend.user.id"
        class="friend-tile clickable position-relative"
        @click="handleClickOnUser(friend.user)"
      >
        <div class="avatar-field position-absolute">
          <img
            draggable="false"
            :src="friend.user.avatarUrl"
            :class="{ deleted: friend.user.deletedAt }"
          />
        </div>
        <span class="friend-name text-uppercase bold position-absolute">{{
          shortenString(friend.user.name, 12)
        }}</span>
        <span class="blue text-uppercase position-absolute medium">{{
          $t("info_badge.more_info")
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import getPublicUserInfos from "@/mixins/getPublicUserInfos.js";
import TablePagination from "@/components/base/pagination.vue";
import InfoBadge from "@/components/base/info-badge";
export default {
  name: "FriendsList",
  components: { InfoBadge, TablePagination },
  mixins: [getPublicUserInfos],
  props: {
    items: {
      type: Array,
      required: true,
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    paginatedFriends() {
      if (this.currentPage === 1) {
        return this.items.slice(0, this.perPage);
      } else {
        let startingIndex = this.perPage * (this.currentPage - 1);
        return this.items.slice(startingIndex, startingIndex + this.perPage);
      }
    },
    totalRows() {
      return this.items.length;
    },
    perPage() {
      if (this.fullWidth && window.innerWidth >= 1200) {
        return 15;
      } else if (this.fullWidth && window.innerWidth < 1200) return 12;
      else if (!this.fullWidth && window.innerWidth < 1200) return 8;
      else return 12;
    },
  },

  mounted() {
    this.$store.dispatch("user/checkFriendRequests");

    if (this.$store.state.user.showGreenBadgeOnTab) {
      this.$store.commit("user/setGreenNotificationBadge", {
        location: "friendsTab",
        display: false,
      });
    }
  },
  methods: {
    handleClickOnUser(user) {
      if (user.deleted) return;
      this.fetchPublicUser(user.id);
    },
    updateCurrentPage(number) {
      this.currentPage = number;
    },
  },
};
</script>

<style lang="scss">
#friends-list {
  h3 {
    width: -moz-fit-content;
    width: fit-content;
  }
  #friends-pagination {
    .pagination {
      top: -12px;
      left: 260px;
    }
  }
  #friends-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px;
    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }
    &.full {
      grid-template-columns: repeat(5, 1fr);
      @media (max-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
      }
    }
    .friend-tile {
      height: 88px;
      background-color: $dark-blue-06;
      border-radius: 16px;
      .avatar-field {
        background-color: black;
        border-radius: 5px;
        width: 55px;
        height: 55px;
        left: 17px;
        top: 17px;
        img {
          max-width: 95%;
        }
      }
      .friend-name {
        font-size: 16px;
        color: #fff8b7;
        top: 8px;
        left: 80px;
      }
      .blue {
        right: 16px;
        bottom: 10px;
        font-size: 14px;
      }
    }
  }
}
</style>
