<template>
  <div id="chat-input" class="mx-auto">
    <div
      class="d-inline-flex justify-content-around align-items-center w-100 h-100"
    >
      <div class="d-inline-flex align-items-center">
        <button
          id="btn-send-message"
          class="button-empty bg-img text-uppercase bold"
          @click="closeEmojiPopover"
        >
          {{ $t("teams.chat.button.send_message") }}
        </button>
        <b-popover
          id="text-message-popover"
          ref="messageSelectPopover"
          target="btn-send-message"
          placement="top"
          custom-class="message-select"
          ><b-row
            ><div class="close-button" @click="closePopover"></div>
            <b-col
              ><div
                v-for="message in textMessages.left"
                :key="message.id"
                class="message-element"
                @click="handleMessageSelect(message.id)"
              >
                "{{ $t(`teams.${message.message}`) }}"
              </div></b-col
            ><b-col
              ><div
                v-for="message in textMessages.right"
                :key="message.id"
                class="message-element"
                @click="handleMessageSelect(message.id)"
              >
                "{{ $t(`teams.${message.message}`) }}"
              </div></b-col
            ></b-row
          ></b-popover
        >
        <img
          id="emoji-bubble"
          alt="chat-smmiley"
          class="clickable mt-3 ml-3"
          draggable="false"
          src="@/assets/img/chat/button-chat-emoji.png"
          @click="closePopover"
        />
        <b-popover
          ref="emojiSelectPopover"
          target="emoji-bubble"
          placement="top"
          custom-class="message-select"
          ><b-row class="emoji-row"
            ><div class="close-button" @click="closeEmojiPopover"></div>
            <div
              v-for="emoji in emojiMessages"
              :key="emoji.id"
              class="emoji-element"
              @click="handleEmojiSelect(emoji.id)"
            >
              <img
                class="emoji-img"
                :alt="emoji.message"
                :src="require(`@/assets/img/chat/emoji/${emoji.message}.svg`)"
              /></div></b-row
        ></b-popover>
      </div>

      <button
        v-if="wishActive"
        id="wish-card-button"
        class="button-empty bg-img text-uppercase bold"
        @click="goToAlbum"
      >
        {{ $t("teams.chat.request_card") }}
      </button>
      <button
        v-else
        id="wish-card-button-timer"
        disabled
        class="button-empty bg-img bold text-uppercase position-relative"
      >
        <CountdownDigital
          :time="$store.state.chat.cardWishTime"
          prefix="-"
          @over="wishActive = true"
        ></CountdownDigital>
        {{ $t("teams.chat.request_card") }}
      </button>
    </div>
  </div>
</template>

<script>
import CountdownDigital from "@/components/base/countdown-digital.vue";
export default {
  name: "ChatInput",
  components: { CountdownDigital },
  props: {
    textMessages: {
      type: Object,
      required: true,
    },
    emojiMessages: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      wishActive: false,
    };
  },
  mounted() {
    this.wishActive = this.$store.getters["chat/cardWishActive"];
  },

  methods: {
    closePopover() {
      this.$refs.messageSelectPopover.$emit("close");
    },
    closeEmojiPopover() {
      this.$refs.emojiSelectPopover.$emit("close");
    },
    handleMessageSelect(id) {
      this.$emit("newMessage", id);
      this.closePopover();
    },
    handleEmojiSelect(id) {
      this.$emit("newMessage", id);
      this.closeEmojiPopover();
    },
    goToAlbum() {
      this.playSoundButtonUpcraft();
      this.wishActive = false;
      this.$router.push({ name: "album", params: { wishModeExternal: true } });
    },
  },
};
</script>

<style lang="scss">
//custom poopover styling

.message-select {
  max-width: 500px;
  .popover-body {
    background-color: white !important;
    border-radius: 16px;
  }
  .arrow:after {
    border-top-color: white !important;
  }

  .close-button {
    right: -14px;
    top: -14px;
    width: 32px;
    height: 32px;
  }
  .message-element {
    cursor: pointer;
    font-family: Ubuntu-bold;
    font-size: 18px;
    &:hover {
      border: 1px solid black;
      margin: -1px;
      &:nth-child(even) {
        border: 1px solid #1f5c87;
      }
    }
  }
  .message-element:nth-child(even) {
    color: #1f5c87;
  }
}
.emoji-row {
  padding: 8px;
  max-width: 300px;
  .emoji-element {
    cursor: pointer;
    margin: 2px;
    &:hover {
      border: 1px solid black;
      margin: 1px;
    }

    .emoji-img {
      width: 30px;
      height: 30px;
    }
  }
}

#chat-input {
  height: 80px;
  background-color: $dark-blue-06;
  font-size: 18px;

  #btn-send-message {
    width: 253px;
    height: 64px;
    background-image: url(~@/assets/img/chat/btn-send-message.png);
    padding: 26px 10px 0 0;
  }
  #emoji-bubble {
    width: 32px;
    height: 32px;
  }
  #wish-card-button {
    width: 278px;
    height: 104px;
    background-image: url(~@/assets/img/chat/btn-wish-card.png);
    padding: 26px 45px 0 0;
  }
  #wish-card-button-timer {
    width: 242px;
    height: 59px;
    background-image: url(~@/assets/img/chat/btn-wish-card-timer.png);
    padding-top: 26px;
    pointer-events: none;
    color: #d3d3d3;
    font-size: 18px;
    .countdown-digital {
      position: absolute;
      color: #ff0000;
      font-family: Ubuntu-medium;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
