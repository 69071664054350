<template>
  <b-row id="friends-vault" no-gutters align-h="center" class="gold-border-box">
    <b-col
      cols="12"
      class="d-flex flex-column align-items-center h-100 justify-content-around"
    >
      <InfoBadge
        id="info-friends-fellowship"
        :info-text="$t('info_badge.friends.fellowship')"
        :headline-text="$t('info_badge.friends.fellowship.headline')"
        placement="right"
      />
      <h3 class="uppercase-subheadline small mt-1 text-center">
        {{ $t("friends.request_friends.treasure.title") }}
      </h3>
      <div id="vault-visual"></div>
      <div
        id="vault-number-field"
        class="d-inline-flex align-items-center justify-content-center"
      >
        <span class="text-white bold">{{ formatNumber(treasurySum) }}</span>
        <img
          draggable="false"
          src="@/assets/img/common/coin1.png"
          alt="gold"
          class="ml-1"
          style="width: 25px"
        />
      </div>
      <div
        v-if="treasuries.length > 0"
        id="vault-info"
        class="text-center clickable"
        @click="$bvModal.show('treasury-top-friends-modal')"
      >
        <span class="blue medium text-uppercase">{{
          $t("treasury.friends.top_friends.button")
        }}</span>
      </div>
      <button
        class="ok-button-green round"
        :disabled="
          treasuries.length === 0 || plunderButtonClicked || !isTimeExpired
        "
        @click="$emit('plunder')"
      >
        {{ $t("friends.request_friends.treasure.button_collect") }}
      </button>
      <TreasuryTopFriendsModal
        v-if="topFriends.length > 0"
        :top-friends="topFriends"
      />
    </b-col>
  </b-row>
</template>
<script>
import TreasuryTopFriendsModal from "@/components/friends/treasury-top-friends-modal.vue";
import InfoBadge from "@/components/base/info-badge";
export default {
  name: "FriendsVault",
  components: { TreasuryTopFriendsModal, InfoBadge },
  props: {
    treasuries: {
      type: Array,
      required: true,
    },
    plunderButtonClicked: {
      type: Boolean,
      default: false,
    },
    treasurySum: {
      type: Number,
      default: 0,
    },
    isTimeExpired: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    topFriends() {
      let friendArray = [];
      this.treasuries.forEach((treasure) => {
        let modifiedTreasure = Object.assign(treasure, { counter: 1 });
        if (friendArray.length > 0) {
          if (
            treasure.friend_id === friendArray[friendArray.length - 1].friend_id
          ) {
            friendArray[friendArray.length - 1].amount += treasure.amount;
            friendArray[friendArray.length - 1].counter++;
          } else {
            friendArray.push(modifiedTreasure);
          }
        } else {
          friendArray.push(modifiedTreasure);
        }
      });
      return friendArray;
    },
    disabledButtonText() {
      if (this.treasuries.length === 0) {
        return this.$t("treasury.friends.error.no_treasuries");
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="scss">
#friends-vault {
  height: 385px;
  #vault-visual {
    height: 154px;
    width: 201px;
    background: url(~@/assets/img/friends/goldfellowship.png) center/100% 100%
      no-repeat;
    margin-top: -22px;
  }
  #vault-number-field {
    margin-top: -30px;
    position: relative;

    height: 48px;
    border-radius: 6px;
    position: relative;
    z-index: 2;
    min-width: 256px;
    background-color: black;
    box-shadow: 0 2px 0 #074f5c;
    span {
      font-size: 26px;
      text-shadow: 0 0 13px rgba(77, 229, 255, 0.6),
        0 0 6px rgba(77, 229, 255, 0.6), 0 0 3px rgba(0, 94, 110, 0.8);
    }
  }
  #vault-info {
    font-size: 14px;
    z-index: 1;
    margin-top: -10px;
  }

  .ok-button-green {
    margin-top: -10px;
    font-size: 18px;
    min-width: 190px;
  }
}
</style>
