<template>
  <b-modal
    id="treasury-top-friends-modal"
    :hide-footer="true"
    modal-class="custom-modal2"
    content-class="rectangular-dark-blue-modal"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <h1 class="uppercase-subheadline" v-html="topFriendsHeadline"></h1>
      <a href="#" class="close-button" @click="close()"> </a>
    </template>
    <template slot="default">
      <b-table
        id="friends-results"
        :items="topFriends"
        :fields="fields"
        @row-clicked="handleClickOnUser"
      >
        <template v-slot:head()="data">{{ $t(data.label) }}</template>

        <template v-slot:cell(name)="data">
          <span class="user-name">{{
            shortenString(data.item.friend.name, 20)
          }}</span>
        </template>
        <template v-slot:cell(levelUps)="data">{{
          data.item.counter
        }}</template>
        <template v-slot:cell(gold)="data">{{
          formatNumber(data.item.amount)
        }}</template>
      </b-table>
    </template>
  </b-modal>
</template>

<script>
import getPublicUserInfos from "@/mixins/getPublicUserInfos.js";
export default {
  name: "TreasuryTopFriendsModal",
  mixins: [getPublicUserInfos],

  props: {
    topFriends: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "treasury.friends.top_friends.name",
          sortable: true,
        },
        {
          key: "levelUps",
          label: "treasury.friends.top_friends.level_ups",
          sortable: true,
        },
        {
          key: "gold",
          label: "treasury.friends.top_friends.earned_gold",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    topFriendsHeadline() {
      return this.$t("treasury.friends.top_friends.title");
    },
  },
  mounted() {},

  methods: {
    handleClickOnUser(row) {
      if (row.friend.deleted_at) return;
      this.fetchPublicUser(row.friend_id);
    },
  },
};
</script>
<style lang="scss">
$modal-width: 736.4px;
$modal-height: auto;
#treasury-top-friends-modal {
  .modal-header {
    display: block;
  }

  .modal-dialog {
    height: 100%;
    width: 100%;
    padding-right: 15px;
  }

  .modal-content {
    border: 2.9px solid $light-blue;
    color: white;
    text-align: center;
    height: $modal-height;
    width: $modal-width;
  }

  table {
    height: 100%;
    thead {
      background-color: $dark-blue-03;
      line-height: 50px;
      border: none;
      th {
        font-size: 14px;
        color: #8e8e8e;
        font-family: Ubuntu-medium;
        text-transform: uppercase;
      }
    }
    tbody {
      tr {
        background-color: $dark-blue-03;
        color: #fff8b7;
        font-family: Ubuntu-bold;
        text-transform: uppercase;
      }
    }
  }
}
</style>
