<template>
  <div
    class="card-wish-bg d-inline-flex align-items-center"
    :class="{ closed: isExpired }"
  >
    <div
      class="card-img-bg"
      :style="{
        backgroundImage:
          'url(' +
          require('@/assets/img/cards/card-background_' +
            message.card.probability.probability +
            '.jpg') +
          ')',
      }"
    >
      <img
        class="card-img to-center-abs"
        :src="message.card.image_urls.image_url"
      />
    </div>
    <div class="card-wish-content-col">
      <p class="text-uppercase mb-0">
        {{ $t("teams.chat.request_specific_card_blank") }}
      </p>
      <p class="text-uppercase bold">
        {{ cardTitle }}
      </p>
      <div class="d-inline-flex justify-content-between w-100">
        <span v-if="!isExpired" class="card-wish-gifting-info"
          >{{ cardsReceived.length }}/{{ cardMaxAmount }}</span
        >
        <button
          class="neutral-button-blue"
          :disabled="isDisabled"
          @click="openGiftAlertModal"
        >
          {{ $t("teams.chat.give_card") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import * as cardsAPI from "@/API/cards.js";
export default {
  name: "CardWishBox",
  props: {
    message: {
      type: Object,
      required: true,
    },
    userGiftee: {
      type: Number,
      required: false,
      default: null,
    },
    cardGiftSuccess: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      cardsReceived: [],
      cardMaxAmount: 0,
      isCompleted: false,
      isExpired: false,
      giftableCards: 0,
      goldReward: 0,
      userCardAmount: 0,
      updateInterval: "",
    };
  },

  computed: {
    cardTitle() {
      return this.$t(
        `trading_cards.cards.${this.message.card.set}_${this.message.card.number}`
      );
    },
    isSelf() {
      return (
        this.$store.getters["user/currentUser"].id === this.message.sender.id
      );
    },
    isDisabled() {
      return (
        this.isSelf ||
        this.giftableCards === 0 ||
        this.alreadyGifted ||
        this.isExpired
      );
    },
    alreadyGifted() {
      if (!this.isSelf) {
        if (this.cardsReceived.length === 0) {
          return false;
        } else {
          let index = this.cardsReceived.findIndex(
            (gift) =>
              gift.user_id === this.$store.getters["user/currentUser"].id
          );
          if (index < 0) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        return null;
      }
    },
    cardRequestNeedsUpdate() {
      return (
        this.userGiftee === this.message.sender.id &&
        this.message.card.id === this.cardGiftSuccess
      );
    },
  },
  watch: {
    cardRequestNeedsUpdate: {
      handler(data) {
        if (data) {
          this.getCardRequestInfos();
        }
      },
    },
  },
  created() {
    this.updateInterval = setInterval(() => {
      if (!this.isExpired && !this.isCompleted) {
        this.getCardRequestInfos();
      } else {
        return;
      }
    }, 30000);
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
  },
  mounted() {
    this.getCardRequestInfos();
    this.getUserCardInfos();
    if (!this.isSelf) {
      this.getOwnCardInfos();
    }
  },
  methods: {
    getCardRequestInfos() {
      cardsAPI
        .fetchUserCardRequest(this.message.sender.id)
        .then((res) => {
          if (
            res.data.data.requests &&
            res.data.data.requests.card_id === this.message.card.id
          ) {
            this.cardMaxAmount = res.data.data.requests.card.request.amount;
            this.cardsReceived = res.data.data.requests.gifts;
            this.isCompleted = res.data.data.requests.is_completed;
            this.goldReward = res.data.data.requests.card.request.gold;
          } else {
            this.isExpired = true;
          }
        })
        .catch((e) => console.log(e));
    },
    getUserCardInfos() {
      cardsAPI
        .fetchUserCardInfos(this.message.sender.id, this.message.card.id)
        .then((res) => (this.userCardAmount = res.data.data.cards.amount))
        .catch((e) => console.log(e));
    },
    getOwnCardInfos() {
      cardsAPI
        .fetchUserCardInfos(
          this.$store.getters["user/currentUser"].id,
          this.message.card.id
        )
        .then((res) => (this.giftableCards = res.data.data.cards.amount))
        .catch((e) => console.log(e));
    },
    openGiftAlertModal() {
      this.$emit(
        "giftAlert",
        this.message.sender.id,
        this.message.card,
        this.goldReward
      );
    },
  },
};
</script>

<style lang="scss">
.button-blue-yellow-border {
  text-transform: uppercase;
  font-size: 12px;
  color: #fff8b7;
}
.card-wish-bg {
  padding: 8px 16px 8px 16px;
  position: relative;

  &.closed {
    filter: grayscale(100%);

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ */
      & {
        background-image: linear-gradient(179deg, #0a2700 7%, #0a2700 71%);
        box-shadow: inset 0 0 2px 0 #0a2708, inset 0 0 4px 0 #0a2700,
          inset 0 0 6px 0 #0a2700;
      }
    }
  }
  .card-img-bg {
    height: 67px;
    width: 44px;
    background-size: 100% 100%;
    position: relative;
    margin-right: 8px;
    border-radius: 3px;

    .card-img {
      max-height: 100%;
      max-width: 100%;
    }
  }
  .card-wish-content-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 16px;
    p {
      font-size: 16px;
      text-shadow: 0 0 2px rgba(0, 0, 0, 0.5), 0 0 2px rgba(0, 0, 0, 0.8);
      font-size: 18px;
      &:first-child {
        font-size: 16px;
      }
    }
    .card-wish-gifting-info {
      font-size: 16px;
    }
    .neutral-button-blue {
      min-width: 128px;
      height: 24px;
      font-family: Ubuntu-medium;
      font-size: 12px;
    }
  }
}
</style>
