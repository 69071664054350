var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"friends-invite-modal","hide-footer":true,"modal-class":"custom-modal2","content-class":"rectangular-dark-blue-modal"},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('h1',{staticClass:"super-headline3-light"},[_vm._v(" "+_vm._s(_vm.$t("friends.friend_requests.popup.title"))+" ")]),_c('span',{staticStyle:{"font-size":"24px"}},[_vm._v(" "+_vm._s(_vm.$t("friends.friend_requests.popup.message"))+" ")]),_c('a',{staticClass:"close-button",attrs:{"href":"#"},on:{"click":function($event){return close()}}})]}}])},[_c('template',{slot:"default"},[_c('b-table',{attrs:{"id":"friends-results","items":_vm.items,"fields":_vm.fields},on:{"row-clicked":_vm.onRowSelected},scopedSlots:_vm._u([{key:"head(name)",fn:function(data){return [_vm._v(_vm._s(_vm.$t(data.label)))]}},{key:"head(level)",fn:function(data){return [_vm._v(_vm._s(_vm.$t(data.label)))]}},{key:"cell(name)",fn:function(data){return [_c('span',{staticClass:"user-name"},[_vm._v(_vm._s(data.item.user.name))])]}},{key:"cell(level)",fn:function(data){return [_vm._v(_vm._s(data.item.user.level))]}},{key:"cell(invite)",fn:function(data){return [(
            !data.item.friendship &&
            !_vm.hasSendRequestBeenClicked(data.item.user.id)
          )?_c('button',{staticClass:"button-blue-md-icon mx-auto w-75",on:{"click":function($event){return _vm.handleAddFriend(data.item.user.id, data.item.user.name)}}},[_c('span',[_vm._v(_vm._s(_vm.$t("popup_user_details.button_friend_request")))]),_c('img',{attrs:{"src":require("@/assets/img/common/plus.png")}})]):(
            (data.item.friendship &&
              data.item.friendship.status === 'OPEN') ||
            (data.item.friendship &&
              data.item.friendship.status === 'PENDING') ||
            (!data.item.friendship &&
              _vm.hasSendRequestBeenClicked(data.item.user.id))
          )?_c('button',{staticClass:"button-blue-md-icon mx-auto w-75",attrs:{"disabled":""}},[_c('span',{staticClass:"small-line"},[_vm._v(_vm._s(_vm.$t("popup_user_details.button_friends")))]),_c('img',{attrs:{"src":require("@/assets/img/common/arrow-yellow.png")}})]):(data.item.friendship.status === 'ACCEPTED')?_c('button',{staticClass:"button-blue-md-icon mx-auto w-75",on:{"click":function($event){return _vm.handleRemoveClick(data.item.user.id)}}},[_c('span',[_vm._v(_vm._s(_vm.$t("popup_user_details.button_remove_friends")))]),_c('img',{attrs:{"src":require("@/assets/img/common/check-green.png")}})]):_vm._e()]}}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }