<template>
  <div id="chat-history" ref="chatHistory" :style="{ height: dynamicHeight }">
    <ChatMessage
      v-for="message in displayedMessages"
      :key="message.time"
      :message="message"
      :user-giftee="successGiftee"
      :card-gift-success="cardGiftSuccess"
      @giftAlert="handleGiftAlert"
    />
    <GiftAlertModal
      id="gift-alert"
      :card="cardToBeGifted"
      :gold-reward="goldReward"
      @cardGifted="handleGiftCard"
    />
  </div>
</template>

<script>
import GiftAlertModal from "./gift-alert-modal.vue";
import ChatMessage from "./chat-message.vue";
import * as cardsAPI from "@/API/cards.js";
export default {
  name: "ChatHistory",
  components: { ChatMessage, GiftAlertModal },
  props: {
    recentMessages: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      userGiftee: null,
      successGiftee: null,
      cardGiftSuccess: null,
      cardToBeGifted: {},
      goldReward: 0,
    };
  },
  computed: {
    dynamicHeight() {
      let baseHeight = 474;
      let teamSize;
      if (this.$store.getters["auth/isLoggedIn"]) {
        teamSize = this.$store.state.teams.userTeam.users.length;
      } else {
        teamSize = 0;
      }
      if (teamSize <= 10) {
        return baseHeight + "px";
      } else {
        let extraMembers = teamSize - 10;
        return baseHeight + extraMembers * 55 + "px";
      }
    },

    displayedMessages: function () {
      return this.$store.state.chat.displayedMessages;
    },
  },
  mounted() {
    this.scrollToBottom();
  },
  updated() {
    this.scrollToBottomUpdate();
  },
  methods: {
    scrollToBottom() {
      let scrollDiv = this.$refs.chatHistory;
      scrollDiv.scrollTop = scrollDiv.clientHeight;
    },
    scrollToBottomUpdate() {
      let scrollDiv = this.$refs.chatHistory;
      scrollDiv.scrollTop = scrollDiv.scrollHeight;
    },
    handleGiftAlert(userId, card, goldReward) {
      this.playSoundChatSendReceive();
      (this.cardToBeGifted = card),
        (this.goldReward = goldReward),
        (this.userGiftee = userId);
      this.$bvModal.show("gift-alert");
    },
    handleGiftCard() {
      this.$bvModal.hide("gift-alert");
      cardsAPI
        .giftCard(this.userGiftee)
        .then(() => {
          this.successGiftee = this.userGiftee;
          this.cardGiftSuccess = this.cardToBeGifted.id;
        })
        .catch((e) => console.log(e));
    },
  },
};
</script>

<style lang="scss">
#chat-history {
  padding: 0;
  border: 0;
  width: 100%;
  overflow-y: auto;
  background-color: $dark-blue-03;
}
</style>
