<template>
  <div id="team-wish-card">
    <div class="wish-card-border">
      <div class="headline to-center-abs text-glow">
        {{ $t("teams.card_badge.headline") }}
      </div>

      <div class="card-icon to-center-abs"></div>

      <div class="text1 to-center-abs text-glow">
        {{ $t("teams.card_badge.text1") }}
      </div>

      <div class="text2 to-center-abs text-glow">
        {{ $t("teams.card_badge.text2") }}
      </div>

      <div class="button-container to-center-abs">
        <div
          v-if="!$store.getters['chat/cardWishActive']"
          class="timer-container to-center-abs"
        >
          <div class="timer-text">
            {{ $t("trading_cards.desire.next_time_new") }}
          </div>
          <div class="timer">
            <CountdownDigital
              :time="$store.state.chat.cardWishTime"
              @over.once="$emit('refreshCardWish')"
            />
          </div>
        </div>
        <div
          class="btn ok-button-green round-new to-center-abs"
          :class="{ disabled: !$store.getters['chat/cardWishActive'] }"
          @click="handleWishClick"
        >
          {{ $t("trading_cards.desire.with_crowns") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountdownDigital from "@/components/base/countdown-digital";
export default {
  name: "TeamWishCard",
  components: { CountdownDigital },
  computed: {},
  mounted() {},
  methods: {
    handleWishClick() {
      this.playSoundButtonUpcraft();
      this.$router.push({ name: "album", params: { wishModeExternal: true } });
    },
  },
};
</script>

<style lang="scss" scoped>
#team-wish-card {
  .wish-card-border {
    border: 6px solid #fff8b7;
    border-radius: 16px;
    min-height: 470px;
    color: white;
    text-align: center;
    line-height: 1.2;

    .text-glow {
      text-shadow: 0 0 16px rgba(77, 229, 255, 0.6),
        0 0 8px rgba(77, 229, 255, 0.6), 0 0 8px rgba(77, 229, 255, 0.6),
        0 0 4px rgba(0, 94, 110, 0.8), 0 0 4px rgba(0, 94, 110, 0.8);
      text-transform: uppercase;
    }

    .headline {
      font-family: Ubuntu-Bold;
      font-size: 1.75rem;
      width: 60%;
      top: 20px;
    }

    .card-icon {
      height: 280px;
      width: 280px;
      background: url(~@/assets/img/friends/teams-card-request-visual-copy.png)
        center/100% 100% no-repeat;
      top: 45px;
    }

    .text1 {
      font-size: 18px;
      font-family: Ubuntu-Bold;
      top: 290px;
      width: 48%;
      min-width: 376px;
    }

    .text2 {
      font-size: 16px;
      font-family: Ubuntu;
      top: 340px;
      width: 48%;
      min-width: 376px;
    }

    .button-container {
      top: 380px;

      .timer-container {
        top: 0;
        background-color: #251a16;
        border-radius: 35px;
        height: 70px;
        width: 44%;
        min-width: 344px;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.84);

        .timer-text {
          color: #ff0000;
          font-size: 13px;
          text-transform: uppercase;
          position: absolute;
          left: 35px;
          top: 7px;
        }

        .timer {
          font-family: Ubuntu-Bold;
          color: #ff0000;
          font-size: 18px;
          position: absolute;
          right: 50px;
          top: 4px;
        }
      }

      .btn {
        width: 54%;
        height: 41px;
        line-height: 34px;
        color: white;
        font-size: 1.313rem;
        font-family: Ubuntu-Bold;
        top: 30px;
        opacity: 1;
        border-radius: 24px;
      }
    }
  }
}
</style>
