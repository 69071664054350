<template>
  <div id="team-table-container">
    <div class="head-row row">
      {{ $t("teams.chat.team_list.title") }}
    </div>

    <b-row class="team-name-container">
      <b-col cols="3" class="text1 d-flex align-items-center">
        {{ $t("popup_user_details.team_name") }}
      </b-col>

      <b-col class="text2 d-flex align-items-center justify-content-center">
        {{ $store.state.teams.userTeam.name }}
      </b-col>
      <b-col
        cols="3"
        class="clickable d-flex align-items-center justify-content-center"
        @click="leaveTeam"
      >
        <div class="text3">
          {{ $t("teams.my_team.leave_team") }}
        </div>
        <span class="cancel position-absolute"></span>
      </b-col>
    </b-row>
    <b-table
      v-if="
        $store.state.teams.userTeam &&
        $store.state.teams.userTeam.users &&
        !error
      "
      id="team-table"
      small
      class="mx-auto"
      :items="mergedUserData"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :tbody-tr-class="highlightSelf"
      thead-class="head-row-text"
      @row-clicked="openDetailsModal"
    >
      <template v-slot:head()="data">{{ $t(data.label) }}</template>
      <template v-slot:head(activity)="data"
        ><div class="w-100 text-right">{{ $t(data.label) }}</div></template
      >

      <!-- <template v-if="rankArray" v-slot:cell(rank)="{ index }" s>
      {{ rankArray[index] }}</template
    > -->

      <template v-slot:cell(name)="{ value }">
        <span class="user-name">{{ shortenString(value, 20) }}</span>
      </template>
      <template v-slot:cell(score)="{ value }"
        ><div class="inline-cell">
          {{ formatNumber(value) }}
          <img
            draggable="false"
            alt="userscore-icon"
            src="@/assets/img/common/icon-user-points.svg"
            style="width: 16px; height: 16px"
          />
        </div>
      </template>
      <template v-slot:cell(activity)="data">
        <div
          class="activityPoint"
          :class="{
            red: data.value === 'offline',
            yellow: data.value === 'inactive',
            deleted: data.value === 'deleted',
          }"
        ></div
      ></template>
    </b-table>
    <ErrorPlaceholder v-else-if="error" class="mt-5" />
  </div>
</template>

<script>
import getPublicUserInfos from "@/mixins/getPublicUserInfos.js";
import ErrorPlaceholder from "@/components/base/error-placeholder.vue";

export default {
  name: "TeamTable",
  components: { ErrorPlaceholder },
  mixins: [getPublicUserInfos],
  props: {
    activityConfig: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 15,
      error: false,
      fields: [
        // { key: "rank", label: "teams.my_team.tab_button.rank", sortable: true },
        {
          key: "name",
          label: "teams.my_team.tab_button.member",
          sortable: true,
        },

        {
          key: "level",
          label: "friends.friends.table_title.level",
          sortable: false,
        },
        {
          key: "activity",
          label: "teams.my_team.tab_button.activity",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    mergedUserData() {
      if (
        this.$store.state.teams.userTeam &&
        this.$store.state.teams.userTeam.users.length > 0
      ) {
        return this.$store.state.teams.userTeam.users.map((user, index) => {
          return {
            id: user.id,
            name: user.name,
            avatar_url: user.avatar_url,
            deleted_at: user.deleted_at,
            level: user.level,
            activity: this.determineActivity(user),
          };
        });
      } else {
        return [];
      }
    },
    // rankArray() {
    //   if (this.mergedUserData.length > 0) {
    //     let arr = [];
    //     let rank = 1;
    //     this.mergedUserData.forEach((item, index) => {
    //       if (index > 0 && item.score < this.mergedUserData[index - 1].score) {
    //         rank++;
    //       }
    //       arr.push(rank);
    //     });
    //     return arr;
    //   } else {
    //     return null;
    //   }
    // },
  },
  mounted() {
    if (this.$store.getters["teams/isTeamMember"]) {
      this.$store.commit("setIsPageLoading", false);
    }
    if (this.mergedUserData === []) {
      this.error = true;
    }
  },
  methods: {
    leaveTeam() {
      this.$store.commit("setIsPageLoading", true);
      this.$store.dispatch("teams/leaveTeam").then(() => {
        if (this.$route.path !== "/teams") {
          this.$router.push("/teams");
        }
      });
    },
    openDetailsModal(row) {
      if (row.deleted_at) return;
      this.fetchPublicUser(row.id);
    },
    highlightSelf(user) {
      if (user.id === this.$store.getters["user/currentUser"].id) {
        return ["align-middle", "highlight"];
      } else {
        return "align-middle";
      }
    },

    determineActivity(user) {
      if (user.deleted_at) return "deleted";
      let lastActive = new Date(user.last_active).getTime();
      let now = Date.now();
      let delta = now - lastActive;
      let minutesSinceActive = delta / 1000 / 60;
      if (
        !user.last_active ||
        minutesSinceActive > this.activityConfig.offline
      ) {
        return "offline";
      } else if (minutesSinceActive <= this.activityConfig.inactive) {
        return "active";
      } else {
        return "inactive";
      }
    },
  },
};
</script>

<style lang="scss">
#team-table-container {
  .head-row {
    background-color: rgba(5, 13, 28, 0.31);
    font-size: 32px;
    text-transform: uppercase;
    font-family: Ubuntu-Medium;
    color: white;
  }

  .team-name-container {
    text-transform: uppercase;
    background-color: #000000;
    box-shadow: 0 2px 3px #44a5b7;
    border-radius: 8px;
    height: 40px;
    line-height: 40px;
    margin-top: 20px;
    line-height: 1.5;
    flex-wrap: nowrap;
    @media (max-width: 1200px) {
      height: 80px;
    }

    .text1 {
      color: #4de5ff;
      font-size: 14px;
      font-family: Ubuntu-Medium;
    }

    .text2 {
      font-family: Ubuntu-Bold;
      text-align: center;
      color: white;
      text-shadow: 0 0 16px rgba(77, 229, 255, 0.6),
        0 0 8px rgba(77, 229, 255, 0.6), 0 0 8px rgba(77, 229, 255, 0.6),
        0 0 4px rgba(0, 94, 110, 0.8), 0 0 4px rgba(0, 94, 110, 0.8);
    }

    .text3 {
      cursor: pointer;
      color: #ff0000;
      font-family: Ubuntu-Medium;
      font-size: 14px;
      position: relative;
      line-height: 1.2;
    }

    .cancel {
      color: white;
      width: 16px;
      height: 16px;
      right: 5px;
      background: url(~@/assets/img/friends/icn-friendrequest-decline.png)
        center/100% 100% no-repeat;
      margin-left: 10px;
    }
  }

  #team-table {
    th,
    td {
      &:first-child {
        text-align: left;
      }
    }
    .activityPoint {
      width: 15px;
      height: 15px;
      margin-left: 90%;

      border-radius: 50%;
      background-color: #43ff00;
      &.red {
        background-color: #ff0000;
      }
      &.yellow {
        background-color: #ffb900;
      }
      &.deleted {
        background-color: rgb(118, 118, 118);
      }
    }
  }
}
</style>
