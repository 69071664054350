<template>
  <TabbedPage>
    <template v-slot:nav-tabs>
      <NavTab
        v-for="(tab, index) in contentTabs"
        :id="index"
        :key="tab.title"
        :href="tab.path"
        :label="$t(tab.title)"
        :locked="tab.locked"
        :german-only="tab.germanOnly"
        :locked-text="tab.lockedText"
        :show-badge="tab.showBadge"
        @clickAttempt="handleClickAttempt(tab.path)"
      ></NavTab
    ></template>
    <template v-slot:main-content>
      <router-view :rewards="rewards" @refreshCardWish="getWishTime"
    /></template>
  </TabbedPage>
</template>

<script>
import * as cardsAPI from "@/API/cards.js";
import NavTab from "@/components/base/nav-tab.vue";
import TabbedPage from "@/views/TabbedPage.vue";

export default {
  name: "TeamsPage",
  components: {
    TabbedPage,
    NavTab,
  },
  data() {
    return {
      nextMatchday: 0,
      rewards: {},
    };
  },
  computed: {
    contentTabs() {
      return [
        {
          title: "friends.tab_button.friends",
          path: "/teams/friends",
          showBadge:
            this.$store.state.user.openFriendRequests.length > 0 &&
            !this.friendsClicked,
        },
        {
          title: this.teamTabTitle,
          path: "/teams",
          locked: !this.$store.getters.teamsEnabled || !this.userCanJoinTeams,
          name: "team-friend-tab-team-members",
          lockedText: this.teamJoinLockText,
        },
        {
          title: "teams.tab_button.chat",
          path: "/teams/chat",
          locked: this.tabIsLocked,
          showBadge:
            this.$store.state.chat.unreadMessages > 0 && !this.chatClicked,
        },
      ];
    },
    tabIsLocked() {
      return (
        !this.$store.getters["teams/isTeamMember"] ||
        !this.$store.getters["teams/teamActive"] ||
        !this.$store.getters.teamsEnabled
      );
    },
    teamTabTitle() {
      if (!this.$store.getters["teams/isTeamMember"]) {
        return "teams.tab_button.join_team";
      } else {
        return "teams.join_team.column_title.team";
      }
    },
    standardLockText() {
      if (!this.$store.getters.teamsEnabled) {
        return this.$t("locked.teams_disabled");
      } else if (!this.$store.getters["teams/isTeamMember"]) {
        return this.$t("locked.not_in_team");
      } else if (!this.$store.getters["teams/teamActive"]) {
        return this.$t("locked.team_not_full");
      } else {
        return "";
      }
    },
    teamJoinLockText() {
      if (!this.$store.getters.teamsEnabled) {
        return this.$t("locked.teams_disabled");
      } else if (!this.userCanJoinTeams) {
        return this.$t("teams.join_team.low_level.alert_message", {
          0: this.teamsUnlockLevel,
        });
      } else if (!this.$store.getters["teams/isTeamMember"]) {
        return this.$t("locked.not_in_team");
      } else if (!this.$store.getters["teams/teamActive"]) {
        return this.$t("locked.team_not_full");
      } else {
        return "";
      }
    },
    teamsUnlockLevel() {
      return this.$store.state.generalConfig.settings["team.join.level"];
    },
    userCanJoinTeams() {
      return (
        this.$store.getters["user/currentUser"].level >= this.teamsUnlockLevel
      );
    },
  },
  mounted() {
    this.getTeamInfos();

    if (this.$store.state.user.showGreenBadgeInMenu) {
      this.$store.commit("user/setGreenNotificationBadge", {
        location: "friendsMenu",
        display: false,
      });
    }
  },
  methods: {
    getTeamInfos() {
      this.getWishTime();
    },

    async getWishTime() {
      let seconds = await cardsAPI.getNextWishTime();
      let now = Date.now();
      // Timer needs milliseconds until deadline
      let wishTime = seconds * 1000 + now;
      this.$store.commit("chat/setCardWishTime", wishTime);
    },

    handleClickAttempt(path) {
      if (!this.$store.getters.teamsEnabled) {
        this.$store.commit("popups/setAlertBannerContent", {
          alertText: this.$t("locked.teams_disabled"),
          type: "fellowship",
          alertHeading: this.$t("locked.teams_disabled.title"),
        });
      } else if (
        !this.$store.getters["teams/isTeamMember"] &&
        !this.userCanJoinTeams &&
        path === "/teams"
      ) {
        this.$store.commit("popups/setAlertBannerContent", {
          alertText: this.$t("teams.join_team.low_level.alert_message", {
            0: this.teamsUnlockLevel,
          }),
          type: "fellowship",
          alertHeading: this.$t("alerts.title.mild_error"),
        });
      } else if (!this.$store.getters["teams/isTeamMember"]) {
        this.$store.commit("popups/setAlertBannerContent", {
          alertText: this.$t("locked.not_in_team"),
          type: "fellowship",
          alertHeading: this.$t("locked.not_in_team.title"),
        });
      } else if (!this.$store.getters["teams/teamActive"]) {
        this.$store.commit("popups/setAlertBannerContent", {
          alertText: this.$t("locked.team_not_full"),
          type: "fellowship",
          alertHeading: this.$t("locked.not_in_team.title"),
        });
      }
    },
  },
};
</script>
<style lang="scss">
.rematch-cost-number {
  font-family: Ubuntu-Bold;
  font-style: italic;
  font-size: 20px;
}
</style>
