var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length > 0)?_c('b-table',{attrs:{"id":"chat-members","items":_vm.items,"fields":_vm.fields},on:{"row-clicked":_vm.openDetailsModal},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"user-name medium text-normal text-left"},[_vm._v(_vm._s(_vm.shortenString(value, 18)))])]}},{key:"cell(online)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 d-flex justify-content-center"},[_c('div',{staticClass:"online-indicator",class:{
          online: _vm.isOnline(item.id),
          deleted: item.deleted_at,
        }})])]}}],null,false,247511314)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }