<template>
  <b-modal
    id="friends-invite-modal"
    :hide-footer="true"
    modal-class="custom-modal2"
    content-class="rectangular-dark-blue-modal"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <h1 class="super-headline3-light">
        {{ $t("friends.friend_requests.popup.title") }}
      </h1>
      <span style="font-size: 24px">
        {{ $t("friends.friend_requests.popup.message") }}
      </span>
      <a href="#" class="close-button" @click="close()"> </a>
    </template>
    <template slot="default">
      <b-table
        id="friends-results"
        :items="items"
        :fields="fields"
        @row-clicked="onRowSelected"
      >
        <template v-slot:head(name)="data">{{ $t(data.label) }}</template>
        <template v-slot:head(level)="data">{{ $t(data.label) }}</template>
        <template v-slot:cell(name)="data">
          <span class="user-name">{{ data.item.user.name }}</span>
        </template>
        <template v-slot:cell(level)="data">{{
          data.item.user.level
        }}</template>
        <template v-slot:cell(invite)="data">
          <button
            v-if="
              !data.item.friendship &&
              !hasSendRequestBeenClicked(data.item.user.id)
            "
            class="button-blue-md-icon mx-auto w-75"
            @click="handleAddFriend(data.item.user.id, data.item.user.name)"
          >
            <span>{{ $t("popup_user_details.button_friend_request") }}</span>
            <img src="@/assets/img/common/plus.png" />
          </button>
          <button
            v-else-if="
              (data.item.friendship &&
                data.item.friendship.status === 'OPEN') ||
              (data.item.friendship &&
                data.item.friendship.status === 'PENDING') ||
              (!data.item.friendship &&
                hasSendRequestBeenClicked(data.item.user.id))
            "
            class="button-blue-md-icon mx-auto w-75"
            disabled
          >
            <span class="small-line">{{
              $t("popup_user_details.button_friends")
            }}</span>
            <img src="@/assets/img/common/arrow-yellow.png" />
            <!-- <TwoOptionsModal
                  @cancel="$bvModal.hide('abort-request-modal')"
                  @confirm="handleAbortRequest"
                  prompt="Freundschaftsanfrage zurückziehen?"
                  id="abort-request-modal"
                  cancelButtonText="Fortsetzen"
                  confirmButtonText="Zurückziehen"
                /> -->
          </button>
          <button
            v-else-if="data.item.friendship.status === 'ACCEPTED'"
            class="button-blue-md-icon mx-auto w-75"
            @click="handleRemoveClick(data.item.user.id)"
          >
            <span>{{ $t("popup_user_details.button_remove_friends") }}</span>
            <img src="@/assets/img/common/check-green.png" />
          </button> </template
      ></b-table>
    </template>
  </b-modal>
</template>

<script>
import getPublicUserInfos from "@/mixins/getPublicUserInfos.js";
export default {
  name: "FriendsInviteModal",
  mixins: [getPublicUserInfos],

  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sendRequestClicked: null,
      friendToBeRemoved: 0,
      fields: [
        {
          key: "name",
          label: "friends.friend_requests.popup.table_title.name",
          sortable: false,
        },
        {
          key: "level",
          label: "friends.friend_requests.popup.table_title.level",
          sortable: true,
        },
        { key: "invite", label: "", sortable: false },
      ],
    };
  },
  mounted() {
    this.$root.$on("ok-end-friendship-modal-search", () => {
      this.$emit("removeFriend", this.friendToBeRemoved);
    });
  },

  methods: {
    handleAddFriend(id, name) {
      this.sendRequestClicked = id;
      this.$emit("inviteFriend", id, name);
    },
    hasSendRequestBeenClicked(id) {
      return this.sendRequestClicked === id;
    },
    handleRemoveClick(id) {
      this.friendToBeRemoved = id;
      this.openAlertWindow(
        "end-friendship-modal-search",
        this.$t("popup_remove_friend.message"),
        true,
        this.$t("popup_remove_friend.button_yes"),
        this.$t("popup_remove_friend.button_no")
      );
    },
    onRowSelected(row) {
      this.fetchPublicUser(row.user.id);
    },
  },
};
</script>
<style lang="scss">
$modal-width: 736.4px;
$modal-height: auto;
#friends-invite-modal {
  .modal-header {
    display: block;
  }

  .modal-dialog {
    height: 100%;
    width: 100%;
    padding-right: 15px;
  }

  .modal-content {
    border: 2.9px solid $light-blue;
    color: white;
    text-align: center;
    height: $modal-height;
    width: $modal-width;
  }

  table {
    height: 100%;
    thead {
      background-image: $table-row-gradient;
      border: 1px solid $light-gold;
      box-shadow: inset 0 0 4px black, inset 0 0 8px black;
      line-height: 50px;
      th {
        font-family: Cinzel-Bold;
        font-size: 21px;
        color: $light-gold;
      }
    }
    tbody {
      tr {
        background-image: $table-row-gradient;
      }
      td {
        &:first-of-type {
          background: none;
        }
        &:last-of-type {
          background: none;
        }
      }
    }
  }
}
</style>
