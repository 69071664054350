<template>
  <b-modal
    id="plunder-success-modal"
    :hide-footer="true"
    modal-class="custom-modal2"
    content-class="rectangular-dark-blue-modal"
    @hidden="collectReward"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <div id="fellowship-gold" class="to-center-abs"></div>

      <h1 class="super-headline3-light">
        {{ $t("treasury.friends.collected.title") }}
      </h1>
      <a href="#" class="close-button" @click="close()"> </a>
    </template>
    <template slot="default"
      ><p>
        {{ $t("treasury.friends.collected.message") }}
      </p>
    </template>
  </b-modal>
</template>

<script>
import AnimationFullRewardEarned from "@/components/animation/animation-full-reward-earned";

export default {
  name: "PlunderSuccessModal",
  mixins: [AnimationFullRewardEarned],
  props: {
    treasurySum: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      rewardSum: 0,
    };
  },
  watch: {
    treasurySum(newValue, oldValue) {
      if (newValue === 0) {
        this.rewardSum = oldValue;
      }
    },
  },
  methods: {
    collectReward: function () {
      let result = [];
      result.push({
        reward: "gold",
        amount: this.formatNumber(this.rewardSum),
      });

      this.AnimationFullRewardEarnedAnimate(result, false);
    },
  },
};
</script>
<style lang="scss">
$modal-width: 784px;
$modal-height: 286px;
#plunder-success-modal {
  .modal-header {
    display: block;
    padding-top: 95px;
    #fellowship-gold {
      width: 329px;
      height: 183px;
      background: url(~@/assets/img/friends/fellowship-gold.png) center/100%
        100% no-repeat;
      top: -95px;
    }
  }

  .modal-dialog {
    height: 100%;
    width: 100%;
    padding-right: 15px;
    height: $modal-height;
    max-width: $modal-width;
    margin: 95px auto;
  }

  .modal-content {
    border: 2.9px solid $light-blue;
    color: white;
    text-align: center;
    height: $modal-height;
    width: $modal-width;

    p {
      font-size: 30px;
      line-height: 1.2;
    }
  }
}
</style>
