<template>
  <b-row
    id="friends-invite"
    no-gutters
    align-h="center"
    class="position-relative drop-shadow-box h-100"
  >
    <b-col
      cols="12"
      class="d-flex flex-column align-items-center justify-content-around text-center"
    >
      <InfoBadge
        id="info-friends-invite"
        :info-text="$t('info_badge.friends.friends_invite')"
        :headline-text="$t('info_badge.friends.friends_invite.headline')"
        placement="right"
      />
      <h3 class="uppercase-subheadline to-center-abs">
        {{ $t("friends.request_friends.title") }}
      </h3>
      <h4 class="to-center-abs">
        {{ $t("friends.request_friends.subtext") }}:
      </h4>
      <div
        class="d-inline-flex w-100 justify-content-center to-center-abs reward-container"
      >
        <div
          class="reward-display d-inline-flex align-items-center justify-content-center"
        >
          <img
            draggable="false"
            src="@/assets/img/common/coin1.png"
            style="width: 56px"
          /><span class="text-white bold ml-3">
            + {{ formatNumber(inviteRewards.gold) }}</span
          >
        </div>
        <div
          class="reward-display d-inline-flex align-items-center justify-content-center ml-3"
        >
          <img
            draggable="false"
            src="@/assets/img/common/crown6.png"
            style="width: 68px"
          />
          <span class="text-white bold ml-3">
            + {{ formatNumber(inviteRewards.crowns) }}</span
          >
        </div>
      </div>
      <div
        class="d-inline-flex align-items-center to-center-abs link-container px-3"
      >
        <div class="d-flex flex-column align-items-center mx-auto mb-3 w-50">
          <button
            v-if="linkCopied"
            class="button-blue-modern round"
            @click.prevent="copyLink"
          >
            {{ $t("friends.request_friends.button_link_copy.copied") }}
            <img
              class="h-50 ml-1"
              draggable="false"
              src="@/assets/img/common/check-green.png"
            />
          </button>
          <button
            v-else
            class="button-blue-modern round"
            @click.prevent="copyLink"
          >
            {{ $t("friends.request_friends.button_link_copy.app") }}
          </button>
          <span id="displayed-link" class="mt-1">{{ inviteLink }}</span>
          <input id="copy-link" type="hidden" :value="inviteLink" />
        </div>
        <div
          class="d-flex flex-column align-items-center mx-auto search-column w-50"
        >
          <b-form inline class="w-100" @submit.prevent="findFriends">
            <b-input-group class="w-100">
              <input
                id="find-friends"
                v-model="friendsQuery"
                :placeholder="$t('friends.request_friends.search_placeholder')"
                type="text"
              />
              <b-input-group-append class="button-container">
                <b-button
                  id="find-friends-search-button"
                  class="pl-0 py-0 pr-3"
                  name="find-friends-button"
                  type="submit"
                  ><img
                    draggable="false"
                    src="@/assets/img/common/icon-search.svg"
                  /><span class="ml-2">{{
                    $t("friends.request_friends.search_button")
                  }}</span></b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form>
        </div>
      </div>

      <div class="foot-note to-center-abs">
        {{ $t("friends.request_friends.foot_note") }}
      </div>

      <FriendsInviteModal
        :items="searchResults"
        @inviteFriend="handleInvite"
        @removeFriend="handleRemove"
      />
    </b-col>
  </b-row>
</template>
<script>
import FriendsInviteModal from "./friends-invite-modal.vue";
import * as FriendsAPI from "@/API/friends.js";
import InfoBadge from "@/components/base/info-badge";

export default {
  name: "FriendsInvite",
  components: {
    InfoBadge,
    FriendsInviteModal,
  },
  props: {
    inviteLink: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      friendsQuery: "",
      searchResults: [],
      requestedFriend: "",
      linkCopied: false,
    };
  },
  computed: {
    inviteRewards() {
      return this.$store.state.generalConfig.settings["mail.invitation.reward"];
    },
  },
  methods: {
    findFriends() {
      let unknownFriend = this.friendsQuery;
      FriendsAPI.findFriends(this.friendsQuery)
        .then((res) => {
          this.searchResults = res.data.data.users.filter(
            (user) => !user.deletedAt
          );
          if (this.searchResults.length > 0) {
            this.$bvModal.show("friends-invite-modal");
          } else {
            this.$store.commit("popups/setAlertBannerContent", {
              alertText: this.$t("friends.request_friends.search.not_found", {
                0: unknownFriend,
              }),
              alertHeading: this.$t("alerts.title.mild_error"),
            });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.status === 422 || e.status === 404) {
            this.$store.commit("popups/setAlertBannerContent", {
              alertText: this.$t("friends.request_friends.search.not_found", {
                0: unknownFriend,
              }),
              alertHeading: this.$t("alerts.title.mild_error"),
            });
          } else {
            this.displayErrorMessage();
          }
        })
        .finally(this.clearInput());
    },
    clearInput() {
      this.friendsQuery = "";
    },
    handleInvite: async function (id, name) {
      this.requestedFriend = name;

      let response = await FriendsAPI.addFriend(id);
      if (response === 200) {
        this.$store.commit("popups/setAlertBannerContent", {
          alertText: this.$t("friends.request_friends.search.request_sent", {
            0: this.requestedFriend,
          }),
          alertHeading: this.$t("alerts.title.success"),
          type: "check",
        });
      }
      if (response === 403) {
        this.$store.commit("popups/setAlertBannerContent", {
          alertText: this.$t(
            "friends.request_friends.search.request_already_sent",
            {
              0: this.requestedFriend,
            }
          ),
          alertHeading: this.$t("alerts.title.error"),
          type: "danger",
          variant: "danger",
        });
      }
    },
    handleRemove(id) {
      FriendsAPI.removeFriend(id)
        .then(this.$emit("friendRemoved"))
        .catch((e) => console.log(e));
      this.$bvModal.hide("friends-invite-modal");
    },
    copyLink() {
      let testingCodeToCopy = document.querySelector("#copy-link");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        this.linkCopied = true;
      } catch (err) {
        this.displayErrorMessage();
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style lang="scss">
#friends-invite {
  min-height: 385px;

  .uppercase-subheadline {
    top: 20px;
    font-size: 1.7rem;
    padding: 0 3rem;
  }
  h4 {
    top: 80px;
    font-family: Ubuntu-medium;
    font-size: 20px;
    color: white;
    text-shadow: 0 0 13px rgba(77, 229, 255, 0.6),
      0 0 6px rgba(77, 229, 255, 0.6), 0 0 3px rgba(0, 94, 110, 0.8);
  }

  .reward-container {
    top: 120px;

    .reward-display {
      height: 85px;
      min-width: 271px;
      border: 2px solid white;
      border-radius: 16px;
      box-shadow: 0 0 26px rgba(77, 229, 255, 0.6),
        0 0 13px rgba(77, 229, 255, 0.6), 0 0 6px rgba(0, 94, 110, 0.8);
      span {
        text-shadow: 0 0 13px rgba(77, 229, 255, 0.6),
          0 0 6px rgba(77, 229, 255, 0.6), 0 0 3px rgba(0, 94, 110, 0.8);
        font-size: 1.625rem;
      }
      @media (max-width: 1200px) {
        min-width: 220px;
      }
    }
  }

  .link-container {
    top: 250px;

    .button-blue-modern {
      width: 182px;
      height: 24px;
      font-size: 16px;
      font-family: Ubuntu;
      line-height: 1;
    }

    #displayed-link {
      background-color: transparent;
      color: $light-blue;
      text-decoration: underline;
      font-size: 12px;
      border: none;
    }
  }

  .search-column {
    margin-top: -20px;

    #find-friends {
      width: 100%;
      border-radius: 8px;
      height: 40px;
      background-image: linear-gradient(#12212b, #020203),
        linear-gradient(#0e1c24, #173242);
      background-clip: padding-box, border-box;
      border: double 0.8px transparent;
      background-size: 100% 100%;
      font-size: 16px;
      color: $light-blue;
      padding-left: 10px;

      &::placeholder {
        color: #404040;
        opacity: 1;
      }
    }

    .button-container {
      position: absolute;
      top: 10px;
      right: 0;

      #find-friends-search-button {
        background-image: linear-gradient(#12212b, #020203),
          linear-gradient(#0e1c24, #173242);
        background-clip: padding-box, border-box;
        background-size: 100% 100%;
        color: $light-blue;
        text-transform: uppercase;
        font-size: 14px;
        border: none;

        img {
          width: 16px;
        }
      }
    }
  }

  .foot-note {
    top: 310px;
    font-family: Ubuntu-Medium;
    font-size: 14px;
    color: white;
    text-align: center;
    width: 74%;
  }
}
</style>
