<template>
  <div class="chat-message container">
    <b-row class="message-content-row"
      ><b-col
        cols="2"
        class="chat-avatar-col gradient-indent"
        :class="{ self: isSelf }"
      >
        <img
          class="chat-avatar-img to-center-abs"
          :src="message.sender.avatar_url" /></b-col
      ><b-col class="chat-content-col"
        ><div class="name-and-time" :class="{ self: isSelf }">
          <span class="user-name bold">{{ message.sender.name }} </span
          ><span class="message-time">{{ messageTime }}</span>
        </div>
        <div class="message-content">
          <span v-if="textMessage" class="message-text">{{
            $t(`teams.${message.message.message}`)
          }}</span>
          <img
            v-else-if="emojiMessage"
            class="message-emoji"
            :src="
              require(`@/assets/img/chat/emoji/${message.message.message}.svg`)
            "
          />
          <CardWishBox
            v-else-if="cardWish"
            :message="message"
            :user-giftee="userGiftee"
            :card-gift-success="cardGiftSuccess"
            @giftAlert="handleGiftAlert"
          /></div></b-col
    ></b-row>
  </div>
</template>

<script>
import CardWishBox from "./chat-card-wish-box.vue";
export default {
  name: "ChatMessage",
  components: { CardWishBox },
  props: {
    message: {
      type: Object,
      required: true,
    },
    userGiftee: {
      type: Number,
      required: false,
      default: null,
    },
    cardGiftSuccess: {
      type: Number,
      required: false,
      default: null,
    },
  },

  computed: {
    messageTime() {
      let date = new Date(this.message.time);
      const options = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      return date.toLocaleDateString(this.$store.state.locale, options);
    },
    textMessage() {
      return (this.message.message && this.message.message.type) === "MESSAGE";
    },
    emojiMessage() {
      return (this.message.message && this.message.message.type) === "EMOJI";
    },
    cardWish() {
      return Object.keys(this.message)[0] === "card";
    },
    isSelf() {
      return (
        this.$store.getters["user/currentUser"].id === this.message.sender.id
      );
    },
  },
  mounted() {},
  methods: {
    handleGiftAlert(userId, cardTitle, goldReward) {
      this.$emit("giftAlert", userId, cardTitle, goldReward);
    },
  },
};
</script>

<style lang="scss">
.chat-message {
  color: white;
  .message-content-row {
    min-height: 47px;
    margin: 16px 0 16px 0;
    .chat-avatar-col {
      max-width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid $light-blue;

      &.self {
        border: 1px solid #43ff00;
      }
      img {
        max-height: 100%;
      }
    }
    .chat-content-col {
      display: flex;
      flex-direction: column;
      font-size: 16px;

      .name-and-time {
        color: $light-blue;
        &.self {
          color: #43ff00;
        }
        .user-name {
          font-size: 16px;
        }
        .message-time {
          font-size: 13px;
        }
      }
      .message-content {
        display: flex;
        align-items: flex-start;
        font-size: 16px;

        .message-text {
          font-size: 16px;
        }
        .message-emoji {
          max-height: 55px;
        }
      }
    }
  }
}
</style>
