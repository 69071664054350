var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"team-table-container"}},[_c('div',{staticClass:"head-row row"},[_vm._v(" "+_vm._s(_vm.$t("teams.chat.team_list.title"))+" ")]),_c('b-row',{staticClass:"team-name-container"},[_c('b-col',{staticClass:"text1 d-flex align-items-center",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$t("popup_user_details.team_name"))+" ")]),_c('b-col',{staticClass:"text2 d-flex align-items-center justify-content-center"},[_vm._v(" "+_vm._s(_vm.$store.state.teams.userTeam.name)+" ")]),_c('b-col',{staticClass:"clickable d-flex align-items-center justify-content-center",attrs:{"cols":"3"},on:{"click":_vm.leaveTeam}},[_c('div',{staticClass:"text3"},[_vm._v(" "+_vm._s(_vm.$t("teams.my_team.leave_team"))+" ")]),_c('span',{staticClass:"cancel position-absolute"})])],1),(
      _vm.$store.state.teams.userTeam &&
      _vm.$store.state.teams.userTeam.users &&
      !_vm.error
    )?_c('b-table',{staticClass:"mx-auto",attrs:{"id":"team-table","small":"","items":_vm.mergedUserData,"fields":_vm.fields,"current-page":_vm.currentPage,"per-page":_vm.perPage,"tbody-tr-class":_vm.highlightSelf,"thead-class":"head-row-text"},on:{"row-clicked":_vm.openDetailsModal},scopedSlots:_vm._u([{key:"head()",fn:function(data){return [_vm._v(_vm._s(_vm.$t(data.label)))]}},{key:"head(activity)",fn:function(data){return [_c('div',{staticClass:"w-100 text-right"},[_vm._v(_vm._s(_vm.$t(data.label)))])]}},{key:"cell(name)",fn:function(ref){
    var value = ref.value;
return [_c('span',{staticClass:"user-name"},[_vm._v(_vm._s(_vm.shortenString(value, 20)))])]}},{key:"cell(score)",fn:function(ref){
    var value = ref.value;
return [_c('div',{staticClass:"inline-cell"},[_vm._v(" "+_vm._s(_vm.formatNumber(value))+" "),_c('img',{staticStyle:{"width":"16px","height":"16px"},attrs:{"draggable":"false","alt":"userscore-icon","src":require("@/assets/img/common/icon-user-points.svg")}})])]}},{key:"cell(activity)",fn:function(data){return [_c('div',{staticClass:"activityPoint",class:{
          red: data.value === 'offline',
          yellow: data.value === 'inactive',
          deleted: data.value === 'deleted',
        }})]}}],null,false,1045941560)}):(_vm.error)?_c('ErrorPlaceholder',{staticClass:"mt-5"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }