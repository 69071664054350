<template>
  <b-col id="friends-container" cols="12">
    <b-row id="vault-invite-row" no-gutters align-h="between">
      <b-col cols="5" xl="5" sm="12" class="h-100">
        <FriendsVault
          :treasuries="treasuries"
          :treasury-sum="treasurySum"
          :friends="friends.length"
          :plunder-button-clicked="plunderButtonClicked"
          :is-time-expired="isTimeExpired"
          @plunder="handlePlunder" /></b-col
      ><b-col cols="7" xl="7" sm="12" class="pl-xl-4 pt-xl-0 pt-sm-5">
        <FriendsInvite
          :invite-link="inviteLink"
          @friendRemoved="fetchFriends"
        />
      </b-col>

      <b-col class="order-sm-12 order-xl-1"
        ><FriendsList
          v-if="friends.length > 0"
          :items="friends"
          :full-width="!showFriendRequests"
      /></b-col>
      <b-col
        v-if="showFriendRequests"
        cols="4"
        sm="12"
        xl="4"
        class="order-sm-1 order-xl-12"
      >
        <FriendsAdd
          @accepted="handleAccept"
          @declined="handleDecline" /></b-col></b-row
    ><PlunderSuccessModal :treasury-sum="treasurySum"
  /></b-col>
</template>

<script>
import FriendsVault from "./friends-vault.vue";
import FriendsInvite from "./friends-invite.vue";
import FriendsList from "./friends-list.vue";
import FriendsAdd from "./friends-add.vue";
import * as FriendsAPI from "@/API/friends.js";
import * as LeagueAPI from "@/API/leagues.js";

import PlunderSuccessModal from "@/components/friends/plunder-success-modal.vue";

export default {
  name: "FriendsContainer",
  components: {
    FriendsInvite,
    FriendsVault,
    FriendsList,
    FriendsAdd,

    PlunderSuccessModal,
  },
  data() {
    return {
      friends: [],
      newFriend: "",
      totalRows: 0,
      inviteLink: "",
      treasuries: [],
      lastLooted: null,
      plunderButtonClicked: false,
    };
  },
  computed: {
    showFriendRequests() {
      return this.$store.state.user.openFriendRequests.length > 0;
    },
    treasurySum() {
      if (this.treasuries.length === 0) {
        return 0;
      } else {
        const maxPossibleGold =
          this.$store.getters["getGeneralConfig"].settings[
            "treasury.friend.max"
          ];
        let goldAmount = 0;
        this.treasuries.forEach((treasury) => {
          goldAmount += treasury.reward.amount;
        });

        if (goldAmount >= maxPossibleGold) {
          return maxPossibleGold;
        }

        return goldAmount;
      }
    },
    isTimeExpired: function () {
      //no date yet
      if (!this.lastLooted) {
        console.log("isTimeExpired2");
        return true;
      }

      console.log("isTimeExpired3", this.lastLooted);
      // //expired after 24 hours
      const nextDate = new Date(this.lastLooted);
      const hour = 1000 * 60 * 60 * 24;
      const hourago = this.getCurrentDate().getTime() - hour;
      console.log("isTimeExpired4", nextDate.getTime() < hourago);

      return nextDate.getTime() < hourago;
    },
  },
  mounted: function () {
    this.loadData();
    this.fetchFriends();
    this.$root.$on("ok-friend-event", () => this.fetchFriends());
  },
  methods: {
    loadData() {
      Promise.all([this.fetchLink(), this.fetchFellowshipInfos()])
        .then((values) => {
          this.inviteLink = values[0];
          this.treasuries = values[1].treasuries;
          this.lastLooted = values[1].last_looted_at;
          this.$store.commit("setIsPageLoading", false);
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("setIsPageLoading", false);
        });
    },
    fetchFriends() {
      FriendsAPI.listFriends()
        .then((res) => {
          this.friends = res;
          this.totalRows = res.length;
        })
        .catch((e) => {
          if (e.data.id === "profile.friend.error.no.friends") {
            this.friends = [];
            this.totalRows = 0;
          }
        });
    },
    async fetchLink() {
      let response = await FriendsAPI.fetchReferrerLink();
      return response.data.data.link;
    },
    async fetchFriendsUserLeagueId(id) {
      let response = await LeagueAPI.fetchUserLeagueById(id);
      return response.data.data.league.league.id;
    },
    async fetchFellowshipInfos() {
      let response = await FriendsAPI.fetchFellowshipInfos();
      return response.data.data;
    },

    handleAccept: async function (id) {
      let response = await FriendsAPI.acceptFriendRequest(id);
      if (response.status === "ok") {
        this.newFriend = response.data.friend.name;
        this.$store.commit("popups/setAlertBannerContent", {
          alertText: this.$t("friends.friend_requests.popup.success", {
            0: this.newFriend,
          }),
          alertHeading: this.$t("alerts.title.success"),
          type: "check",
        });
        this.$store.dispatch("user/checkFriendRequests");
        this.fetchFriends();
      } else {
        this.displayErrorMessage();
      }
    },
    handleDecline: async function (id) {
      let response = await FriendsAPI.declineFriendRequest(id);
      if (response.status === "ok") {
        this.newFriend = response.data.friend.name;
        this.$store.commit("popups/setAlertBannerContent", {
          alertHeading: this.$t("alerts.title.success"),
          alertText: this.$t("friends.friend_requests.popup.rejected", {
            0: this.newFriend,
          }),
          type: "check",
        });

        this.$store.dispatch("user/checkFriendRequests");
      } else {
        this.displayErrorMessage();
      }
    },
    handlePlunder() {
      this.plunderButtonClicked = true;
      FriendsAPI.plunderFellowshipGold()
        .then(() => {
          this.$bvModal.show("plunder-success-modal");
          this.fetchFellowshipInfos().then((res) => {
            this.treasuries = res.treasuries;
            this.lastLooted = res.last_looted_at;
          });
        })
        .catch((e) => {
          if (e.data.id) {
            this.$store.commit("popups/setAlertBannerContent", {
              alertText: e.data.id,
              alertHeading: this.$t("alerts.title.error"),
              type: "danger",
              variant: "danger",
            });
          } else {
            this.displayErrorMessage();
          }
        });
    },
  },
};
</script>

<style lang="scss">
#friends-container {
  padding: 12px 0 0 0;
  position: relative;
  max-width: 1500px;
  margin: auto;
  .info-badge-orange {
    right: 20px;
    top: 10px;

    &#info-friends-friendslist {
      right: -42px;
      top: 2px;
    }
  }
}
</style>
