<template>
  <div id="join-team" class="container-fluid">
    <b-table
      v-if="!error"
      id="all-teams"
      small
      :items="teams"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      thead-class="head-row-text"
    >
      <template v-slot:head()="item">{{ $t(item.label) }}</template>
      <template v-slot:head(join)></template>

      <template v-slot:cell(size)="{ item }">
        <span>{{ item.users_count }}/{{ item.size }}</span>
      </template>
      <template v-slot:cell(min-level)>
        <span>Level {{ teamMinLevel }}</span>
      </template>

      <template v-slot:cell(join)="{ item }">
        <button
          v-if="item.users_count < item.size"
          class="neutral-button-blue"
          @click="handleJoin(item.id)"
        >
          {{ $t("teams_and_friends.join_team.button_join_team") }}
        </button>
        <span v-else class="text-uppercase">{{
          $t("teams.join_team.team_is_full")
        }}</span>
      </template>
    </b-table>
    <ErrorPlaceholder v-else class="mt-5" />
    <tablePagination
      v-if="!error"
      v-model="currentPage"
      :per-page="perPage"
      :total-rows="totalRows"
    />
  </div>
</template>

<script>
import tablePagination from "@/components/base/pagination.vue";
import * as teamAPI from "@/API/teams.js";
import ErrorPlaceholder from "@/components/base/error-placeholder.vue";

export default {
  name: "JoinTeam",
  components: { tablePagination, ErrorPlaceholder },

  data() {
    return {
      teams: [],
      currentPage: 1,
      totalRows: 1,
      perPage: 20,
      error: false,
      fields: [
        {
          key: "name",
          label: "teams.join_team.column_title.team",
          sortable: true,
        },

        {
          key: "size",
          label: "teams.join_team.column_title.free_space",
          sortable: true,
        },
        {
          key: "min-level",
          label: "teams.join_team.column_title.level_requirement",
          sortable: true,
        },
        { key: "join", label: " ", sortable: false },
      ],
    };
  },
  computed: {
    teamMinLevel() {
      return this.$store.state.generalConfig.settings["team.join.level"];
    },
    user: function () {
      return this.$store.getters["user/currentUser"];
    },
  },
  mounted() {
    teamAPI
      .fetchAllOpenTeams()
      .then((res) => {
        this.teams = res.data.data.teams;
        this.totalRows = res.data.data.teams.length;
      })
      .catch((e) => {
        this.error = true;
        console.log(e);
      })
      .finally(() => this.$store.commit("setIsPageLoading", false));
  },
  methods: {
    handleJoin(team) {
      if (this.user.level < this.teamMinLevel) {
        this.$store.commit("popups/setAlertBannerContent", {
          alertText: this.$t("teams.join_team.low_level.alert_message", {
            0: this.teamMinLevel,
          }),
          type: "fellowship",
          alertHeading: this.$t("alerts.title.mild_error"),
        });
        return;
      }

      this.$emit("join", team);
    },
  },
};
</script>

<style lang="scss">
#join-team {
  padding: 0;
  max-width: 80%;
  th,
  td {
    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }
  }
  #all-teams {
    color: white;
    font-size: 22px;
    text-align: center;
  }
}
</style>
