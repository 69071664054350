<template>
  <div id="friends-add" class="mt-5 pl-3">
    <div class="w-100">
      <h3 class="uppercase-subheadline">
        {{ $t("friends.friend_requests.title") }}
      </h3>
      <div id="friend-request-pagination">
        <table-pagination
          :per-page="perPage"
          :total-rows="totalRows"
          @input="updateCurrentPage"
        ></table-pagination>
      </div>
    </div>
    <div id="friend-request-grid">
      <div
        v-for="friend in paginatedRequests"
        :key="friend.user.id"
        class="friend-request-tile position-relative"
      >
        <div class="avatar-field position-absolute">
          <img
            draggable="false"
            :src="friend.user.avatar_url"
            :class="{ deleted: friend.user.deletedAt }"
          />
        </div>
        <span class="friend-name text-uppercase bold position-absolute">{{
          shortenString(friend.user.name, 20)
        }}</span>
        <span
          class="blue text-uppercase position-absolute medium clickable"
          @click="handleClickOnUser(friend.user)"
          >{{ $t("info_badge.more_info") }}</span
        >
        <div
          id="accept-reject-buttons"
          class="w-25 h-75 position-absolute d-flex flex-column justify-content-around"
        >
          <button
            class="button-blue-md-icon"
            @click="$emit('accepted', friend.id)"
          >
            <span>{{ $t("friends.friend_requests.button_accept") }}</span>
            <img src="@/assets/img/common/check-green.png" />
          </button>
          <button
            class="button-blue-md-icon"
            @click="$emit('declined', friend.id)"
          >
            <span>{{ $t("friends.friend_requests.button_decline") }}</span>
            <img src="@/assets/img/common/x-red.svg" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getPublicUserInfos from "@/mixins/getPublicUserInfos.js";
import TablePagination from "@/components/base/pagination.vue";

export default {
  name: "FriendsAdd",
  components: { TablePagination },
  mixins: [getPublicUserInfos],

  data() {
    return {
      perPage: 4,
      currentPage: 1,
    };
  },

  computed: {
    openRequests() {
      return this.$store.state.user.openFriendRequests;
    },
    totalRows() {
      return this.openRequests.length;
    },
    paginatedRequests() {
      if (this.currentPage === 1) {
        return this.openRequests.slice(0, this.perPage);
      } else {
        let startingIndex = this.perPage * (this.currentPage - 1);
        return this.openRequests.slice(
          startingIndex,
          startingIndex + this.perPage
        );
      }
    },
  },
  methods: {
    handleClickOnUser(user) {
      if (user.deleted) return;
      this.fetchPublicUser(user.id);
    },
    handleAccept(item) {
      this.$emit("accepted", item.id);
    },
    handleDecline(item) {
      console.log(item);
      this.$emit("declined", item.id);
    },
    updateCurrentPage(number) {
      this.currentPage = number;
    },
  },
};
</script>

<style lang="scss">
#friend-request-pagination {
  .pagination {
    top: 3px;
    left: 162px;
  }
}
#friend-request-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
  .friend-request-tile {
    height: 88px;
    background-image: linear-gradient(#274b60, #0a1d28);
    border-radius: 16px;
    .avatar-field {
      background-color: black;
      border-radius: 5px;
      width: 55px;
      height: 55px;
      left: 17px;
      top: 17px;
      img {
        max-width: 95%;
      }
    }
    .friend-name {
      font-size: 16px;
      color: #fff8b7;
      top: 8px;
      left: 80px;
    }
    .blue {
      left: 150px;
      bottom: 14px;
      font-size: 14px;
    }
    #accept-reject-buttons {
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 19px;
      .button-blue-md-icon {
        height: 24px;
        font-size: 14px;
        text-shadow: 0 0 2px rgba(0, 0, 0, 0.5), 0 0 2px rgba(0, 0, 0, 0.8);
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
</style>
