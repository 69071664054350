var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-wish-bg d-inline-flex align-items-center",class:{ closed: _vm.isExpired }},[_c('div',{staticClass:"card-img-bg",style:({
      backgroundImage:
        'url(' +
        require('@/assets/img/cards/card-background_' +
          _vm.message.card.probability.probability +
          '.jpg') +
        ')',
    })},[_c('img',{staticClass:"card-img to-center-abs",attrs:{"src":_vm.message.card.image_urls.image_url}})]),_c('div',{staticClass:"card-wish-content-col"},[_c('p',{staticClass:"text-uppercase mb-0"},[_vm._v(" "+_vm._s(_vm.$t("teams.chat.request_specific_card_blank"))+" ")]),_c('p',{staticClass:"text-uppercase bold"},[_vm._v(" "+_vm._s(_vm.cardTitle)+" ")]),_c('div',{staticClass:"d-inline-flex justify-content-between w-100"},[(!_vm.isExpired)?_c('span',{staticClass:"card-wish-gifting-info"},[_vm._v(_vm._s(_vm.cardsReceived.length)+"/"+_vm._s(_vm.cardMaxAmount))]):_vm._e(),_c('button',{staticClass:"neutral-button-blue",attrs:{"disabled":_vm.isDisabled},on:{"click":_vm.openGiftAlertModal}},[_vm._v(" "+_vm._s(_vm.$t("teams.chat.give_card"))+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }