<template>
  <b-row id="chat-content-row" no-gutters class="mx-auto">
    <b-col cols="12" class="uppercase-subheadline">{{
      $t("teams.chat.headline")
    }}</b-col>
    <b-col id="chat-members-col" cols="3" class="mr-3"> <ChatMembers /></b-col
    ><b-col id="chat-history-col">
      <span class="text-uppercase bold light-yellow">{{
        $t("teams.chat.history.title")
      }}</span>

      <ChatHistory v-if="!error" />
      <ErrorPlaceholder v-else-if="error" />
      <ChatInput
        v-if="!error"
        :text-messages="textMessages"
        :emoji-messages="emojiMessages"
        @newMessage="handleNewMessage"
      /> </b-col
  ></b-row>
</template>

<script>
import ChatMembers from "./chat-members.vue";
import ChatHistory from "./chat-history.vue";
import ChatInput from "./chat-input.vue";

import ErrorPlaceholder from "@/components/base/error-placeholder.vue";
import * as ChatAPI from "@/API/chat.js";
export default {
  name: "ChatContainer",
  components: {
    ChatMembers,
    ChatHistory,
    ChatInput,

    ErrorPlaceholder,
  },
  data() {
    return {
      recentMessages: [],
      messageTemplates: [],
      error: false,
    };
  },
  computed: {
    textMessages() {
      if (this.messageTemplates.length > 0) {
        let texts = this.messageTemplates.filter(
          (mes) => mes.type === "MESSAGE"
        );
        let halfLength = Math.floor(texts.length / 2);
        let leftSide = texts.slice(0, halfLength);
        let rightSide = texts.slice(halfLength, texts.length);
        return { left: leftSide, right: rightSide };
      } else {
        return {};
      }
    },
    emojiMessages() {
      if (this.messageTemplates.length > 0) {
        let texts = this.messageTemplates.filter((mes) => mes.type === "EMOJI");
        return texts;
      } else {
        return [];
      }
    },
  },
  created() {
    this.fetchPredefinedMessages();
  },
  mounted() {
    if (this.$store.state.chat.unreadMessages > 0) {
      this.$store.commit("chat/setUnreadMessages", 0);
    }
  },
  methods: {
    fetchPredefinedMessages() {
      ChatAPI.getPredefinedMessages()
        .then((res) => {
          this.messageTemplates = res.data.data.messages;
          this.$store.commit("setIsPageLoading", false);
        })
        .catch((e) => {
          this.error = true;
          console.log(e);
        });
    },
    handleNewMessage(id) {
      ChatAPI.postMessage(id).catch((e) => console.log(e));
    },
  },
};
</script>

<style lang="scss">
.light-yellow {
  color: #fff8b7;
  font-size: 16px;
}
#chat-content-row {
  min-height: 468px;
  max-width: 1500px;
  #chat-members-col {
    height: 100%;
  }
  #chat-history-col {
    height: 100%;
  }
}
</style>
